import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../../components/Button";
import Typography from "../../components/Typography";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          COMO FUNCIONA
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                {/* <Box
                  component="img"
                  src="/static/themes/onepirate/productHowItWorks1.svg"
                  alt="suitcase"
                  sx={image}
                /> */}
                <Typography variant="h5" align="center">
                  Primeiro, entre em contato conosco para discutir sua visão e
                  requisitos. Seja por telefone, e-mail ou através do nosso
                  formulário de contato, estamos prontos para ouvir suas
                  necessidades e responder às suas perguntas.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                {/* <Box
                  component="img"
                  src="/static/themes/onepirate/productHowItWorks2.svg"
                  alt="graph"
                  sx={image}
                /> */}
                <Typography variant="h5" align="center">
                  Após entendermos completamente seus objetivos e necessidades,
                  nossa equipe irá fornecer um orçamento detalhado e
                  transparente. Este documento irá incluir todos os aspectos do
                  projeto, desde o escopo do trabalho até o prazo de entrega e
                  os custos associados.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                {/* <Box
                  component="img"
                  src="/static/themes/onepirate/productHowItWorks3.svg"
                  alt="clock"
                  sx={image}
                /> */}
                <Typography variant="h5" align="center">
                  Uma vez que todos os detalhes estejam acordados e aprovados,
                  nossa equipe talentosa começará a transformar sua visão em
                  realidade. Utilizando as mais recentes tecnologias e práticas
                  de design, iremos desenvolver uma solução sob medida que
                  atenda às suas expectativas e impulsione o sucesso do seu
                  negócio.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          href="https://api.whatsapp.com/send?phone=+5541987996018&text=Ol%C3%A1+vim+do+site+hmti.app.br%2C+quero+um+or%C3%A7amento."
          target="_blank"
          rel="noopener noreferrer" // recomendação para segurança
          sx={{ minWidth: 200 }}
        >
          Contato
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
