import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import Home from "./pages/siteHmti/Home"; // Importe novo componente

function isAuthenticated() {
  const token = localStorage.getItem("token");
  return token && token.length > 0;
}

function App() {
  const isLoggedIn = isAuthenticated();

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
      <Analytics />
    </>
  );
}

export default App;
