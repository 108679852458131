import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../../components/Typography";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={"../../desenvolvimento-de-softwares-1.jpg"}
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Software
              </Typography>
              <Typography variant="h5">
                {
                  "Desenvolvemos software personalizado para atender às necessidades específicas do seu negócio. Com uma abordagem centrada no usuário e expertise técnica, criamos soluções robustas e escaláveis que impulsionam a eficiência e o crescimento da sua empresa."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={"../../criacao-de-aplicativo.jpg"}
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Apps
              </Typography>
              <Typography variant="h5">
                {
                  "Nossos aplicativos são projetados para oferecer experiências intuitivas e envolventes para os usuários, seja para dispositivos móveis ou web. Desde aplicativos móveis nativos até soluções multiplataforma, nossa equipe desenvolve aplicativos sob medida que agregam valor ao seu público-alvo e fortalecem sua presença digital."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={"../../consultoria.jpg"}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Consultoria
              </Typography>
              <Typography variant="h5">
                {
                  "Oferecemos serviços de consultoria especializada para ajudar sua empresa a alcançar seus objetivos de negócios. Desde análises de requisitos até estratégias de implementação, nossa equipe de consultores experientes trabalha em estreita colaboração com você para identificar oportunidades, superar desafios e maximizar o retorno sobre o investimento em tecnologia."
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
