import * as React from "react";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

const backgroundImage =
  "https://buildfire.com/wp-content/uploads/2017/10/learn-to-code-mobile-app-fast-1.jpg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Transformando idéias em realidade
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Construindo soluções inteligentes para o seu sucesso digital.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="https://api.whatsapp.com/send?phone=+5541987996018&text=Ol%C3%A1+vim+do+site+hmti.app.br%2C+quero+um+or%C3%A7amento."
        target="_blank"
        rel="noopener noreferrer" // recomendação para segurança
        sx={{ minWidth: 200 }}
      >
        Contato
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Descubra a experiência
      </Typography>
    </ProductHeroLayout>
  );
}
