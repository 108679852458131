import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "../../components/Typography";

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 9,
      }}
    >
      <Button
        sx={{
          border: "4px solid currentColor",
          borderRadius: 0,
          height: "auto",
          py: 2,
          px: 5,
        }}
        href="https://api.whatsapp.com/send?phone=+5541987996018&text=Ol%C3%A1+vim+do+site+hmti.app.br%2C+quero+um+or%C3%A7amento."
        target="_blank"
        rel="noopener noreferrer" // recomendação para segurança
      >
        <Typography variant="h4" component="span">
          Ainda precisa de ajuda?
        </Typography>
      </Button>

      <Typography variant="subtitle1" sx={{ my: 3 }}>
        Nós estamos aqui para te ajudar, só clicar no botão!
      </Typography>
      {/* <Box
        component="img"
        src="/static/themes/onepirate/productBuoy.svg"
        alt="buoy"
        sx={{ width: 60 }}
      /> */}
    </Container>
  );
}

export default ProductSmokingHero;
